import React from "react";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
const DesktopHeader = () => {
  return (
    <>
      <AppBar
        position="fixed"
        sx={{ background: "#90909050", boxShadow: "none" }}
      >
        <Toolbar>
          <Box
            position={"relative"}
            component="img"
            sx={{
              height: 100,
              width: 100,
              maxHeight: { xs: 50, md: 100 },
              maxWidth: { xs: 50, md: 100 },
            }}
            alt="logo"
            src="../../../assets/logo.png"
          />
          <Typography
            noWrap
            sx={{
              fontSize: {
                md: "1.8rem",
                lg: "1.8rem",
                xl: "2rem",
              },
              display: { xs: "felx", sm: "flex" },
              fontFamily: "monospace",
              fontWeight: { lg: 700, md: 700, sm: 500, xs: 100 },
              letterSpacing: { lg: ".3rem", md: ".1rem", sm: "none" },
              color: "black",
              textDecoration: "none",
              flexGrow: 1,
            }}
          >
            Steed Pharmaceutical Trading
          </Typography>

          <Button
            href="#products"
            variant="outlined"
            sx={{
              marginX: 1,
              display: { xs: "none", sm: "none", md: "flex" },
              padding: ".8rem",
              letterSpacing: ".3rem",
              lineHeight: 1.5,
              fontSize: "1rem",
              fontWeight: 600,
              borderColor: "#48D1CC",
              color: "black",
              fontFamily: "monospace",
              borderRadius: ".5rem",
              textTransform: "none", // Prevents automatic uppercase transformation
              "&:hover": {
                borderColor: "#48D1CC",
                backgroundColor: "#48D1CC", // Keeps background transparent on hover
              },
            }}
          >
            Our Products
          </Button>
          <Button
            href="#aboutus"
            variant="outlined"
            sx={{
              marginX: 1,
              display: { xs: "none", sm: "none", md: "flex" },
              padding: ".8rem",
              letterSpacing: ".3rem",
              lineHeight: 1.5,
              fontSize: "1rem",
              fontWeight: 600,
              borderColor: "#48D1CC",
              color: "black",
              fontFamily: "monospace",
              borderRadius: ".5rem",
              textTransform: "none", // Prevents automatic uppercase transformation
              "&:hover": {
                borderColor: "#48D1CC",
                backgroundColor: "#48D1CC", // Keeps background transparent on hover
              },
            }}
          >
            About
          </Button>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default DesktopHeader;
