import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import ProductCard from "./component/productCard";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import SimpleProductCard from "./component/simpleProductCard";
import Box from "@mui/material/Box";
import Grow from "@mui/material/Grow";

const Products = () => {
  const isMobile = useMediaQuery("(max-width:1024px)");
  const [isDentalProductsOpen, setIsDentalProductsOpen] = useState(false);
  const [isPharmaProductsOpen, setIsPharmaProductsOpen] = useState(false);
  return (
    <Grid
      container
      spacing={2}
      rowSpacing={3}
      sx={{ padding: 4 }}
      id={"products"}
    >
      <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
        <Box
          component={"img"}
          src="../../../assets/DC256.png"
          alt="Dental"
          onClick={() => setIsDentalProductsOpen((prev) => !prev)}
          sx={{
            "&:hover": {
              cursor: "pointer",
            },
          }}
        />
      </Grid>
      {isDentalProductsOpen ? (
        <Grid item xs={12}>
          <Typography
            variant="body1"
            sx={{ fontFamily: "monospace", fontSize: "1.2rem" }}
          >
            <strong>Logidex</strong> is a Turin-based company, born from the
            intuition of three partners and family members with different
            backgrounds but united by a single vision.In 2012, the sole
            director, already a founding partner of other successful companies
            and with extensive experience in various multinational
            pharmaceutical companies, decided to create Logidex to pass on his
            pharmaceutical know-how to his children. Primiano, an interventional
            cardiologist, is the scientific consultant and partner of Logidex
            who guarantees that the supplements are created under the guidance
            of proven clinical studies and scientific publications.
          </Typography>
          <Typography
            variant="body1"
            sx={{ fontFamily: "monospace", fontSize: "1.2rem" }}
          >
            <strong>Tina</strong>, an international lawyer with experience in
            the pharmaceutical sector, is responsible for the strong
            international development that Logidex has been experiencing over
            the years. Thanks to them, the technical team can count on
            prestigious collaborations including that with the Department of
            Pharmacology of the University of Turin.
          </Typography>
        </Grid>
      ) : null}
      {isDentalProductsOpen ? (
        <Grow in={isDentalProductsOpen} timeout={600}>
          <Grid container item spacing={2}>
            <Grid item xs={12} sm={6} lg={3}>
              {/* PRODUCT 1 */}
              <SimpleProductCard
                title={"Oroxil"}
                src={"../../../assets/DP1.jpg"}
                description={
                  "Unique combination for mouth inflammation,  ulcers. Best choice after surgery and extraction. The only alternative for Chlorhexidine."
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              {/* PRODUCT 2 */}
              <SimpleProductCard
                title={"DentYucl-Special Care"}
                src={"../../../assets/DP3.jpg"}
                description={
                  "Special care for Special needs. New technology for diabetic patients to prevent the diabetes common mouth retardation."
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              {/* PRODUCT 3 */}
              <SimpleProductCard
                title={"DentYucl-Whitening"}
                src={"../../../assets/DP5.jpg"}
                description={"New technology for whitening and oral hygiene."}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              {/* PRODUCT 4 */}
              <SimpleProductCard
                title={"DentYucl-Smoking"}
                src={"../../../assets/DP2.jpg"}
                description={
                  "ReFresh your mouth back with the first smoker aid toothpaste ever."
                }
              />
            </Grid>
          </Grid>
        </Grow>
      ) : null}

      <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
        <Box
          component={"img"}
          src="../../../assets/P256.png"
          alt="Pharma"
          sx={{
            "&:hover": {
              cursor: "pointer",
            },
          }}
          onClick={() => setIsPharmaProductsOpen((prev) => !prev)}
        />
      </Grid>
      {isPharmaProductsOpen ? (
        <Grid item xs={12}>
          <Typography
            variant="body1"
            sx={{ fontFamily: "monospace", fontSize: "1.2rem" }}
          >
            <strong>VCS FARMA Pharmaceutical</strong> laboratory specializing in
            products for cosmetics, personal hygiene care, orthopedics, medical
            supplies and oral hygiene.
          </Typography>
          <Typography
            variant="body1"
            sx={{ fontFamily: "monospace", fontSize: "1.2rem" }}
          >
            <strong>Advanced Biomedical srl (ABM)</strong> is a pharmaceutical
            company, produces food supplement, medical device and cosmetics
            based in Italy. We have a team of experts in formulating of new
            products, Our intent, as well as being a superior producer of
            dietary supplements, medical device and cosmetics, and continually
            working on improving our production by following the latest
            scientific discoveries.
          </Typography>
        </Grid>
      ) : null}
      {isPharmaProductsOpen ? (
        <Grow in={isPharmaProductsOpen} timeout={600}>
          <Grid container item spacing={2}>
            <Grid item xs={12} sm={6} lg={3}>
              {/* PRODUCT 5 */}
              <ProductCard
                title={"Anita"}
                src={"../../../assets/P3.jpg"}
                subheader={"30 capsules per box"}
                description={
                  "Supports mom and child during Pregnancy and Lactation. Full spectrum of ingredients in the same capsule."
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              {/* PRODUCT 6 */}
              <ProductCard
                title={"Anita Plus"}
                subheader={"30 capsules per box"}
                src={"../../../assets/P1.jpg"}
                description={
                  "Unique formula with liposomal Iron and Quatro Folic technology Supports mom and child during Pregnancy and Lactation. Full spectrum of ingredients in the same capsule."
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              {/* PRODUCT 7 */}
              <ProductCard
                title={"Prima suppositories"}
                subheader={"10 suppositories per box"}
                src={"../../../assets/P2.jpg"}
                description={
                  "A development unique and particularly effective formulation for the maintenance and the restoration of normal bacterial flora of the vaginal mucous membrane ."
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              {/* PRODUCT 8 */}
              <ProductCard
                title={"Joy Gummy"}
                subheader={"30 capsules per box"}
                src={"../../../assets/P4.jpg"}
                description={
                  "Multi-Vitamins with Iron Daily supplement for children. Maintain healthy growth, brain development and boost immune system."
                }
              />
            </Grid>
          </Grid>
        </Grow>
      ) : null}
    </Grid>
  );
};

export default Products;
