import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { ListItemButton } from "@mui/material";

const MobileHeader = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const toggleDrawer = () => {
    setDrawerOpen(true);
  };
  return (
    <>
      <AppBar
        position="fixed"
        sx={{ background: "transparent", boxShadow: "none" }}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer}
          >
            <MenuIcon />
          </IconButton>
          <Drawer
            open={drawerOpen}
            onClose={() => setDrawerOpen(false)}
            anchor="left"
            sx={{
              "& .MuiDrawer-paper": {
                width: 225, // Set the desired width here
              },
            }}
          >
            <List>
              <ListItem>
                <ListItemButton component="a" href="#products">
                  <ListItemText primary="Our Product" />
                </ListItemButton>
              </ListItem>
              <ListItem>
                <ListItemButton component="a" href="#aboutus">
                  <ListItemText primary="About" />
                </ListItemButton>
              </ListItem>
            </List>
          </Drawer>
          <Box
            position={"relative"}
            component="img"
            sx={{
              height: 100,
              width: 100,
              maxHeight: { xs: 50, md: 100 },
              maxWidth: { xs: 50, md: 100 },
            }}
            alt="logo"
            src="../../../assets/logo.png"
          />
          <Typography
            noWrap
            sx={{
              fontSize: {
                xs: "1rem",
                sm: "1.2rem",
                md: "1.8rem",
              },
              display: { xs: "felx", sm: "flex" },
              fontFamily: "monospace",
              fontWeight: { lg: 700, md: 700, sm: 500, xs: 100 },
              letterSpacing: { lg: ".3rem", md: ".1rem", sm: "none" },
              color: "black",
              textDecoration: "none",
              flexGrow: 1,
            }}
          >
            Steed Pharmaceutical Trading
          </Typography>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default MobileHeader;
